





































































































































import { Component, Vue } from 'vue-property-decorator';
import PriceList from '@/components/pricelist.vue';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {
    PriceList
  },
  metaInfo: metaInfo({
    title: 'Jak funguje půjčení dodávky | Kakadoo',
    url: buildUrl('/jak-to-funguje')
  })
})
export default class HowItWorks extends Vue {
  mounted() {
    if (this.$route.hash === '#vyzvednuti') {
      this.$nextTick(() => {
        this.scrollTo('how-to-pickup');
      });
    }
  }
  scrollTo(elementId: string) {
    const el = document.getElementById(elementId) as HTMLElement;

    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
